import styled from "styled-components"

import Grid from "@material-ui/core/Grid"

export const TabText = styled.h2`
  cursor: pointer;
  color: white;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 56px;
  text-align: center;
  transition: all 0.3s ease;

  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: 18px;
  }

  &:after {
    content: "";
    position: relative;
    background-color: transparent;
    transition: all 0.3s ease;
    height: 8px;
    display: block;
    margin-top: 1em;
  }

  &.active {
    &:after {
      background-color: #df272f;
    }
    color: #df272f;
  }
`

export const Wrapper = styled.section`
  padding-top: 4em;
  padding-bottom: 4em;
  background-color: #ffffff;
`

export const Title = styled.h2`
  margin-bottom: 0.5em;
  text-align: center;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(46)};
`

export const Subtitle = styled.p`
  margin-bottom: 2em;
  text-align: center;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
`

export const GridContainer = styled(Grid)`
  align-items: center !important;
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2em;
`

export const Icon = styled.img`
  margin-right: 2.5em;
`

export const Text = styled.p`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.pxToRem(22)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
`

export const Align = styled.div`
  margin-top: 3em;
  text-align: center;
`
