import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import Grid from "@material-ui/core/Grid"
import BoatCard from "../../components/boat-card/boat-card.component"
import React from "react"

const BoatBuilder = ({ boat }) => {
  return (
    <SectionWrapper bg="primary" contained>
      <Grid container spacing={2}>
        {boat &&
          boat.length > 0 &&
          boat.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={`${item?.title}-${index}`}>
              <BoatCard
                name={item.button?.title}
                url={item.button?.url}
                img={item.image}
                urlChange
              />
            </Grid>
          ))}
      </Grid>
    </SectionWrapper>
  )
}

export default BoatBuilder
