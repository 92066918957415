import React from "react"

import MiscContent from "./layouts/MiscContent"
import HomeHero from "./layouts/HomeHero"
import ExploreBoats from "./layouts/ExploreBoats"
import TextImageRow from "./layouts/TextImageRow"
import BuildSection from "./layouts/BuildSection"
import BoatBuilder from "./layouts/BoatBuilder"
import GeneralHero from "./layouts/GeneralHero"
import ContactInformation from "./layouts/ContactInformation"
import Inventory from "./layouts/Inventory"
import Models from "./layouts/Models"
import ColumnGrid from "./layouts/ColumnGrid"
import FeaturesSection from "./layouts/FeaturesSection"

const getLayout = (layout, content) => {
  const layoutName = layout?.fieldGroupName?.split("_").pop().trim()
  if (!layoutName) return null
  switch (layoutName) {
    case "MiscContent":
      return <MiscContent {...layout} content={content} />
    case "HomeHero":
      return <HomeHero {...layout} />
    case "ExploreBoats":
      return <ExploreBoats {...layout} />
    case "TextImageRow":
      return <TextImageRow {...layout} />
    case "BuildSection":
      return <BuildSection {...layout} />
    case "BoatBuilder":
      return <BoatBuilder {...layout} />
    case "GeneralHero":
      return <GeneralHero {...layout} />
    case "ContactInformation":
      return <ContactInformation {...layout} />
    case "Inventory":
      return <Inventory {...layout} />
    case "Models":
      return <Models {...layout} />
    case "ColumnGrid":
      return <ColumnGrid {...layout} />
    case "FeaturesSection":
      return <FeaturesSection {...layout} />
  }
}

export default getLayout
