import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import getLayout from "../../get-layout-util"

export const query = graphql`
  query PageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      isFrontPage
      featuredImage {
        node {
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
          altText
          title
        }
      }
      seo {
        cornerstone
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
      }
      id
      uri
      pageBuilder {
        layouts {
          __typename
          ... on WpPage_Pagebuilder_Layouts_MiscContent {
            ...MiscContent
          }
          ... on WpPage_Pagebuilder_Layouts_HomeHero {
            ...HomeHero
          }
          ... on WpPage_Pagebuilder_Layouts_ExploreBoats {
            ...ExploreBoats
          }
          ... on WpPage_Pagebuilder_Layouts_TextImageRow {
            ...TextImageRow
          }
          ... on WpPage_Pagebuilder_Layouts_BuildSection {
            ...BuildSection
          }
          ... on WpPage_Pagebuilder_Layouts_BoatBuilder {
            ...BoatBuilder
          }
          ... on WpPage_Pagebuilder_Layouts_GeneralHero {
            ...GeneralHero
          }
          ... on WpPage_Pagebuilder_Layouts_ContactInformation {
            ...ContactInformation
          }
          ... on WpPage_Pagebuilder_Layouts_Inventory {
            ...Inventory
          }
          ... on WpPage_Pagebuilder_Layouts_Models {
            ...Models
          }
          ... on WpPage_Pagebuilder_Layouts_ColumnGrid {
            ...ColumnGrid
          }
          ... on WpPage_Pagebuilder_Layouts_FeaturesSection {
            ...FeaturesSection
          }
        }
        pageConfiguration {
          hideHeader
          hideFooter
        }
      }
    }
  }
`

const PageTemplate = ({ data }) => {
  const { seo, slug, content, pageBuilder, title } = data.wpPage
  const layouts = pageBuilder.layouts || []
  return (
    <Layout
      {...pageBuilder.pageConfiguration}
      seo={seo}
      slug={slug}
      pageTitle={title}
    >
      {layouts.map(layout => getLayout(layout, content))}
    </Layout>
  )
}

export default PageTemplate
