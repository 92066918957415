import Grid from "@material-ui/core/Grid"
import styled from "styled-components"

export const Wrapper = styled.section`
  padding-top: 4em;
  padding-bottom: 4em;
  background-color: #ffffff;
`

export const Title = styled.h2`
  margin-bottom: 0.5em;
  text-align: center;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(46)};
`

export const Subtitle = styled.p`
  margin-bottom: 2em;
  text-align: center;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
`

export const GridContainer = styled(Grid)`
  align-items: center !important;
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2em;
`

export const Icon = styled.img`
  margin-right: 2.5em;
`

export const AboutTextWrapper = styled.div`
  p {
    line-height: 32px;
    font-size: 1rem;
  }
`

export const Text = styled.p`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.pxToRem(22)};
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
`

export const Align = styled.div`
  margin-top: 3em;
  text-align: center;
`
