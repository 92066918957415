import Grid from "@material-ui/core/Grid"
import React from "react"
import SectionTitle from "../../components/section-title/section-title.component"
import parse from "html-react-parser"
import * as S from "../../styles/pages/contact-us.styles"
import CustomImage from "../../components/custom-image/custom-image.component"

const ContactInformation = ({ title, grid, titleFollowUs, socialMedia }) => {
  return (
    <S.CustomSection contained spacing={4}>
      {title && <SectionTitle>{parse(title)}</SectionTitle>}
      <Grid container>
        {grid &&
          grid.length > 0 &&
          grid.map((item, index) => (
            <Grid item xs={12} md={4} key={`${item?.title}-${index}`}>
              {item?.title && (
                <S.ColTitle color="primary">{item.title}</S.ColTitle>
              )}
              {item?.information && <>{parse(item.information)}</>}
            </Grid>
          ))}
      </Grid>
      <div className="social-media">
        {titleFollowUs && <SectionTitle>{titleFollowUs}</SectionTitle>}
        <Grid container>
          {socialMedia &&
            socialMedia.length > 0 &&
            socialMedia.map((item, index) => (
              <Grid item xs={4} spacing={2} key={`${item?.title}-${index}`}>
                {item?.button && (
                  <a href={item.button?.url} target={item?.button?.target}>
                    <CustomImage src={item.icon} />
                  </a>
                )}
              </Grid>
            ))}
        </Grid>
      </div>
    </S.CustomSection>
  )
}

export default ContactInformation
