import React from "react"
import * as S from "./build-section.styles.jsx"
import SectionTitle from "../../components/section-title/section-title.component"
import CustomTypography from "../../components/custom-typography/custom-typography.component"
import Container from "@material-ui/core/Container"
import { createTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const BuildSection = ({ image, title, text, button }) => {
  const defaultTheme = createTheme()
  const matches = useMediaQuery(defaultTheme.breakpoints.up("md"))

  return (
    <S.Wrapper>
      {matches && <S.BgImg src={image} />}
      <S.Content>
        <Container>
          {title && (
            <SectionTitle colorInherit mb={1}>
              {title}
            </SectionTitle>
          )}
          {text && (
            <CustomTypography fontSize={1.25} colorInherit>
              {text}
            </CustomTypography>
          )}
        </Container>
      </S.Content>

      {button?.title && (
        <S.CircularBtn url={button.url}>
          <span>{button.title}</span>
        </S.CircularBtn>
      )}
    </S.Wrapper>
  )
}

export default BuildSection
