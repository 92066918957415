import React, { useEffect, useState } from "react"
import * as S from "./inventory.styles.jsx"
import BoatCard from "../../components/boat-card/boat-card.component"
import Grid from "@material-ui/core/Grid"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { graphql, useStaticQuery } from "gatsby"

const Inventory = ({ tabs }) => {
  const data = useStaticQuery(graphql`
      query inventoryBoats {
          allWpInventoryBoat {
              edges {
                  node {
                      title
                      slug
                      uri
                      inventoryBoatsFields {
                          featuredImagePageInventory {
                              featuredImage {
                                  altText
                                  sourceUrl
                                  localFile {
                                      childImageSharp {
                                          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                                      }
                                  }
                              }
                          }
                          inventoryTypeInventory {
                              type
                          }
                      }
                  }
              }
          }
      }
  `)

  const [selectedTab, setSelectedTab] = useState("trade_in")
  const [selectedBoats, setSelectedBoats] = useState([])

  const inventoryBoatsData = data.allWpInventoryBoat.edges

  useEffect(() => {
    if (selectedTab === "brokerage") {
      setSelectedBoats(
        inventoryBoatsData.filter(
          inventory =>
            inventory.node.inventoryBoatsFields.inventoryTypeInventory.type ===
            "brokerage"
        )
      )
    }
    if (selectedTab === "trade_in") {
      setSelectedBoats(
        inventoryBoatsData.filter(
          inventory =>
            inventory.node.inventoryBoatsFields.inventoryTypeInventory.type ===
            "trade_in"
        )
      )
    }
  }, [selectedTab])

  return (
    <SectionWrapper bg="primary" contained>
      <Grid
        style={{ paddingBottom: "3em", justifyContent: "center" }}
        container
        spacing={2}
      >
        <Grid item xs={12} sm={12} md={6}>
          <S.TabText
            className={selectedTab === "trade_in" ? "active" : ""}
            onClick={() => setSelectedTab("trade_in")}
          >
            {tabs[0].text}
          </S.TabText>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <S.TabText
            className={selectedTab === "brokerage" ? "active" : ""}
            onClick={() => setSelectedTab("brokerage")}
          >
            {tabs[1].text}
          </S.TabText>
        </Grid>
      </Grid>
      <Grid style={{ justifyContent: "center" }} container spacing={2}>
        {selectedBoats.map(inventory => {
          return (
            <Grid item xs={12} sm={6} md={4}>
              <BoatCard
                name={inventory.node.title}
                url={inventory.node.uri}
                img={
                  inventory.node.inventoryBoatsFields.featuredImagePageInventory
                    .featuredImage.sourceUrl
                }
                urlChange
              />
            </Grid>
          )
        })}
      </Grid>
    </SectionWrapper>
  )
}
export default Inventory
