import React from "react"
import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"

import * as S from "./text-img-row.styles"
import { Container, Grid } from "@material-ui/core"
import CustomImage from "../custom-image/custom-image.component"

const TextImgRow = ({ reverse, smaller, left, gatsbyImg, children }) => {

  return (
    <S.Wrapper>
      <Container>
        <S.GridContainer direction={reverse&&"row-reverse"}   container spacing={6}>
          <S.GridItem item xs={12} md={6} smaller={smaller} order={{ xs: 2, sm: 1 }}>
            <CustomImage src={gatsbyImg} />
          </S.GridItem>
          <Grid item xs={12} md={6} order={{ xs: 2, sm: 1 }}>
            <S.Content>{children}</S.Content>
          </Grid>
        </S.GridContainer>
      </Container>
    </S.Wrapper>
  )
}

export default TextImgRow
