import React from "react"
import * as S from "./home-hero.styles.jsx"
import Container from "@material-ui/core/Container"
import { Typography } from "@material-ui/core"
import CustomButton from "../../components/custom-button/custom-button.component"

const HomeHero = ({ title, subtitle, button }) => {
  return (
    <S.Wrapper>
      <S.VideoBg poster="placeholder-video.jpg" autoPlay muted loop playsInline src="https://player.vimeo.com/external/689710735.hd.mp4?s=088f31d3fa513d9a1fbeb66b77d44cf4808ffc0b&profile_id=169" />
      <div className="overlay" />
      <Container>
        <S.Text>
          {title && <h1>{title}</h1>}
          {subtitle && <Typography>{subtitle}</Typography>}
          {button?.title && (
            <CustomButton href={button.url}>{button.title}</CustomButton>
          )}
        </S.Text>
      </Container>
    </S.Wrapper>
  )
}
export default HomeHero
