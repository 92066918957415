import styled, { keyframes } from "styled-components"
import TransitionLink from "../../components/transition-link/transition-link.component"
import CustomImage from "../../components/custom-image/custom-image.component"

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(223, 39, 47, .8);
  }
  70% {
    box-shadow: 0 0 0 30px rgba(223, 39, 47, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(223, 39, 47, 0);
  }

`

export const Wrapper = styled.section`
  //background: url("https://admin.midnightboats.com/wp-content/uploads/2020/09/section.png") no-repeat center center;
  //background-size: cover;
  position: relative;
  color: white;
  text-align: center;
  top: 5px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    color: ${({ theme }) => theme.palette.primary.main};
    padding: 5em 0;
  }
`

export const BgImg = styled(CustomImage)`
  width: 100%;
  height: auto;
`

export const Content = styled.div`
  color: inherit;
  margin: auto;
  padding-bottom: 3em;
  ${({ theme }) => theme.breakpoints.up("md")} {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 744px;
    width: 100%;
    padding-bottom: 0;
  }
`

export const CircularBtn = styled(TransitionLink)`
  width: 130px;
  height: 130px;
  border: 0;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%) scale(1);
  background-color: #df272f;
  color: white;
  display: flex;
  align-items: center;
  line-height: 24px;
  justify-content: center;
  font-weight: 600;
  box-shadow: 0 0 0 0 rgba(223, 39, 47, 0.8);
  animation: ${pulse} 2s infinite;
  span {
    padding: 1.5em;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    position: absolute;
    bottom: 20%;
    width: 160px;
    height: 160px;
  }
`
