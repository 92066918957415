import React from "react"
import * as S from "./section-wrapper.styles.jsx"
import PropTypes from "prop-types"
import Container from "@material-ui/core/Container"

const SectionWrapper = ({ children, className, style, bg, contained }) => (
  <S.Wrapper className={`${className} sectionWrapper`} bg={bg} style={style}>
    {contained ? <Container>{children}</Container> : children}
  </S.Wrapper>
)

SectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  bg: PropTypes.string,
}

export default SectionWrapper
