import React from "react"
import * as S from "./boat-card.styles.jsx"
import { navigate } from "gatsby-link"
import CustomImage from "../custom-image/custom-image.component"

const BoatCard = ({ img, name, details, url, showDetails, urlChange }) => {
  return (
    <S.Wrapper
      onClick={() => (urlChange ? navigate(url) : navigate(`/${url}`))}
    >
      <S.TopWrapper>
        <S.ImgWrapper className={urlChange ? "" : "size"}>
          <CustomImage src={img} />
        </S.ImgWrapper>
        <S.Title>{name}</S.Title>
      </S.TopWrapper>

      <S.ActionsWrapper>
        <S.Container container spacing={2}>
          {/*            {showDetails && details && details.map(({ name, value }) => (
              <Grid item xs={4}>
                <S.DetailItem>{value}</S.DetailItem>
                <S.Description>{name}</S.Description>
              </Grid>
            ))}*/}
        </S.Container>
      </S.ActionsWrapper>
    </S.Wrapper>
  )
}
export default BoatCard
