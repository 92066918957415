import styled from "styled-components"

const getBgColor = (color, theme) => {
  switch (color) {
    case "primary":
      return `background-color: ${theme.palette.primary.main}; color: white`
    case "secondary":
      return `background-color: ${theme.palette.secondary.main}; color: white`
    case "gray":
      return `background-color: #F1F7F9`
  }
}

export const Wrapper = styled.section`
  ${({ theme }) => theme.sectionPadding};
  color: ${({ theme }) => theme.palette.text.primary};
 ${({ bg, theme }) => bg ? getBgColor(bg, theme) : ''};
 
 .MuiGrid-container {
    align-items: center;
 }
 
 .MuiGrid-container .MuiButton-label {
    ${({ theme }) => theme.breakpoints.down("sm")} {
      text-align: center;
    }
  }
`