import React from "react"
import TextImgRow from "../../components/text-image-row/text-img-row.component"
import SectionTitle from "../../components/section-title/section-title.component"
import CustomButton from "../../components/custom-button/custom-button.component"
import parse from "html-react-parser"

const TextImageRow = ({ reverse, smaller, left, right, gatsbyImg }) => {
  return (
    <TextImgRow
      gatsbyImg={left?.image ?? gatsbyImg}
      reverse={reverse}
      smaller={smaller}
    >
      {right?.title && <SectionTitle mb={1}>{parse(right.title)}</SectionTitle>}
      {right?.text && <div className="paragraph">{parse(right.text)}</div>}
      {right?.button?.title && (
        <CustomButton href={right.button.url}>
          {right.button.title}
        </CustomButton>
      )}
    </TextImgRow>
  )
}

export default TextImageRow
