import styled from "styled-components"
import BlueInvertArrow from "../../images/blue-invert-arrow.png"
import Circles from "../../images/circles-full.png"

export const Wrapper = styled.section`
  min-height: 600px;
  height: 100vh;
  color: white;
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.palette.primary.main};

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    //z-index: 1;
    //background-color:  ${({ theme }) => theme.palette.primary.main};
    background-color: rgba(0, 0, 0, 0.4);
    &:after {
      content: "";
      z-index: 2;
      height: 200px;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      background: url(${BlueInvertArrow}) no-repeat center center;
      background-size: cover;
    }
  }

  &:before {
    content: "";

    /*The following commented line holds the dotted circle pattern in the background*/
    /*background: url(${Circles}) no-repeat center center;*/

    bottom: 0;
    z-index: 2;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1200px;
    background-size: cover;
    position: absolute;
    right: 0;
    height: 100%;
    width: 100%;
  }
`

export const VideoBg = styled.video`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`

export const Text = styled.div`
  position: relative;
  z-index: 4;
  margin: auto;
  text-align: center;
  max-width: 750px;

  h1 {
    font-size: ${({ theme }) => theme.typography.pxToRem(36)};
  }

  p {
    margin-bottom: 2em;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    p {
      margin-bottom: 8em;
    }

    h1 {
      font-size: ${({ theme }) => theme.typography.pxToRem(56)};
    }
  } ;
`
