import styled, { css } from "styled-components"
import { IconButton, Typography } from "@material-ui/core"

import WhiteArrow from "../../images/white-invert-arrow.png"
import SelectionCircle from "../../images/selection-circle.png"

export const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.palette.primary.main};
  ${({ theme }) => theme.sectionPadding};
  color: white;
  text-align: center;
  position: relative;
  transition: all 0.3s ease;
  padding-bottom: 10em;

  ${({ theme }) => theme.breakpoints.up("lg")} {
  }

  &:after {
    content: "";
    height: 200px;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: url(${WhiteArrow}) no-repeat center center;
    background-size: cover;
  }

  .slick-current {
    transform: scale(1.2);
  }

  .slick-slide {
    z-index: 2;
    position: relative;
    transition: all 0.3s ease;
  }

  .slick-list {
    z-index: 2;
  }

  .slick-slider {
    &:after {
      content: "";
      z-index: 1;
      background: url(${SelectionCircle}) no-repeat center center;
      position: absolute;
      background-size: contain;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      width: 40%;
      height: 100%;

      //width: 440px;
      //height: 240px;
      ${({ theme }) => theme.breakpoints.down("sm")} {
        width: 100%;
      }
    }
  }

  .slick-dots {
    bottom: -70px;
    //&:after {
    //  content: '';
    //  position: absolute;
    //  width: 15px;
    //  height: 15px;
    //  top: 7px;
    //  border-radius: 50%;
    //  left: 50%;
    //  transform: translateX(-50%);
    //  border: 1px solid red;
    //}
    li {
      button {
        &:before,
        &:after {
          color: white;
          opacity: 1;
        }
      }

      &.slick-active {
        button {
          &:before {
            color: ${({ theme }) => theme.palette.secondary.main};
          }
        }
      }
    }
  }
`

export const SlideWrapper = styled.div`
  img {
    padding: 1em;

    ${({ theme }) => theme.breakpoints.up("md")} {
      padding: 3em;
    }
  }
`

export const BoatName = styled(Typography)`
  text-transform: uppercase;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-top: 5em;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  a,
  button {
    min-width: 206px;
  }
  ${({ theme }) => theme.breakpoints.down("xs")} {
    flex-direction: column;
    gap: 1rem;
  }
`
const ArrowCircle = css`
  content: "";
  width: 120px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  cursor: pointer;
  z-index: 3;

  .MuiSvgIcon-root {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`

export const CustomNextArrow = styled(IconButton)`
  right: -60px;
  ${ArrowCircle}

  .MuiSvgIcon-root {
    left: 1em;
    width: 1.5em;
    height: 1.5em;
  }
`

export const CustomPrevArrow = styled(IconButton)`
  left: -60px;
  ${ArrowCircle}

  .MuiSvgIcon-root {
    right: 1em;
    width: 1.5em;
    height: 1.5em;
  }
`
