import styled from "styled-components"
import { Typography } from "@material-ui/core"
import SelectionCircle from "../../images/selection-circle.png"
import WhiteInvertArrow from "../../images/white-invert-arrow.png"
import Grid from "@material-ui/core/Grid"

export const Wrapper = styled.div`
  cursor: pointer;
  border-radius: 8px;
`

export const TopWrapper = styled.div`
  background-color: #2c3854;
  padding: 2em;
  border-top: 4px solid transparent;
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  &:after {
    content: "";
    height: 50px;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: url(${WhiteInvertArrow}) no-repeat center center;
    background-size: cover;
  }

  &.details {
    border-top: 4px solid ${({ theme }) => theme.palette.secondary.main};
    justify-content: start;

    .title {
      margin-top: 0;
    }
  }
`

export const ImgWrapper = styled.div`
  position: relative;
  width: 100%;

  &:after {
    content: "";
    z-index: 1;
    background: url(${SelectionCircle}) no-repeat center center;
    position: absolute;
    background-size: contain;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: 100%;
    height: 100%;
  }

  .gatsby-image-wrapper {
    > div {
      padding-top: 0 !important;
    }
  }

  img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 2;
  }

  &.size {
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }
`

export const Title = styled(Typography)`
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  margin: 1.5em 0;
`

export const ActionsWrapper = styled.div`
  background-color: white;
  padding: 2em 1em;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

export const Container = styled(Grid)`
  max-width: 320px;
  margin: auto;
`

export const DetailItem = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(12)};
  font-weight: 700;
  color: #2a334e;
`

export const Description = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(12)};
  color: #2a334e;
`
