import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Typography } from "@material-ui/core"
import BlueInvertArrow from "../../images/blue-invert-arrow.png"

export const CustomSection = styled(SectionWrapper)`
  position: relative;
  padding-bottom: 8em;
  padding-top: 8em;

  h2 {
    text-align: center;
    margin-bottom: 1em;
  }

  .MuiGrid-container {
    position: relative;
    z-index: 5;
  }

  .MuiGrid-item {
    text-align: center;

    a,
    p {
      font-size: ${({ theme }) => theme.typography.pxToRem(18)};
      text-decoration: none;
      color: ${({ theme }) => theme.palette.primary.main};
    }

    a {
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
      }
    }
  }

  &:after {
    content: "";
    height: 200px;
    position: absolute;
    z-index: 3;
    left: 0;
    bottom: 0;
    right: 0;
    background: url(${BlueInvertArrow}) no-repeat center center;
    background-size: cover;
  }

  .social-media {
    margin-top: 4em;

    .MuiGrid-container {
      max-width: 200px;
      margin: auto;

      a {
        img {
          max-width: 25px;
          min-width: 25px;
        }

        &:hover {
          border-bottom: unset;
        }
      }
    }
  }
`

export const ColTitle = styled(Typography)`
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  font-weight: bold;
`
