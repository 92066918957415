import React from "react"
import parse from "html-react-parser"
import TextImgRow from "../../components/text-image-row/text-img-row.component"

const FeaturesSection = ({ features }) => {
  return (
    <>
      {features.map((feature, index) => (
        <TextImgRow
          reverse={!!feature?.reverse}
          gatsbyImg={feature.image}
          key={`${feature.title}-${index}`}
        >
          <>{parse(feature?.title)}</>
          <>{parse(feature?.content)}</>
        </TextImgRow>
      ))}
    </>
  )
}

export default FeaturesSection
