import React from "react"
import TopPageBanner from "../../components/top-page-banner/top-page-banner.component"
import parse from "html-react-parser"

const GeneralHero = ({ image, tag, title, text, arrow }) => {
  return (
    <TopPageBanner bgImg={image} tag={tag} arrow={arrow}>
      {title && <h1>{title}</h1>}
      {text && <p>{parse(text)}</p>}
    </TopPageBanner>
  )
}

export default GeneralHero
