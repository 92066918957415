import Grid from "@material-ui/core/Grid"
import React from "react"
import SectionTitle from "../../components/section-title/section-title.component"
import parse from "html-react-parser"
import * as S from "./column-grid.styles"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import Container from "@material-ui/core/Container"

const ColumnGrid = ({ title, leftText, rightText }) => {
  return (
    <SectionWrapper>
      <Container>
        <div style={{ textAlign: "center", paddingBottom: "4em" }}>
          {title && (
            <SectionTitle mb={0.5} colorInherit>
              {parse(title)}
            </SectionTitle>
          )}
        </div>
        <Grid style={{ lineHeight: "32px" }} container spacing={4}>
          {leftText && (
            <Grid item xs={12} sm={6}>
              <S.AboutTextWrapper>{parse(leftText)}</S.AboutTextWrapper>
            </Grid>
          )}
          {rightText && (
            <Grid item xs={12} sm={6}>
              <S.AboutTextWrapper>{parse(rightText)}</S.AboutTextWrapper>
            </Grid>
          )}
        </Grid>
      </Container>
    </SectionWrapper>
  )
}

export default ColumnGrid
