import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"

import * as S from "./explore-boats.styles.jsx"
import Container from "@material-ui/core/Container"
import CustomButton from "../../components/custom-button/custom-button.component"
import Grid from "@material-ui/core/Grid"
import { createTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import CustomImage from "../../components/custom-image/custom-image.component"

const ExploreBoats = ({ boatSlider, buttons }) => {
  let slider = useRef(null)

  const boats = boatSlider

  const defaultTheme = createTheme()
  const matches = useMediaQuery(defaultTheme.breakpoints.up("md"))

  const [currentSlide, setCurrentSlide] = useState(0)
  const [boatInfo, setBoatInfo] = useState(boats[currentSlide])

  const next = () => {
    slider.slickNext()
  }

  const previous = () => {
    slider.slickPrev()
  }

  useEffect(() => {
    setBoatInfo(boats[currentSlide])
  }, [currentSlide])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    afterChange: current => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 750,
        settings: {
          arrows: false,
          centerMode: false,
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <S.Wrapper>
      <Container maxWidth="xl">
        {/*<SectionTitle mb={2} colorInherit>*/}
        {/*  EXPLORE OUR BOATS*/}
        {/*</SectionTitle>*/}
        {matches && (
          <S.CustomPrevArrow onClick={previous}>
            <NavigateBeforeIcon />
          </S.CustomPrevArrow>
        )}
        <Slider {...settings} ref={c => (slider = c)}>
          {boats &&
            boats.length > 0 &&
            boats.map((boat, index) => (
              <S.SlideWrapper key={`boat-${index}`}>
                <CustomImage src={boat.image} />
              </S.SlideWrapper>
            ))}
        </Slider>
        <S.BoatName>{boatInfo.boatName}</S.BoatName>
        <S.ButtonsWrapper>
          <CustomButton
            href={`/boat-builder${boatInfo.boatUrl}`}
            className="light"
          >
            DESIGN YOUR MIDNIGHT
          </CustomButton>

          {boatInfo.boatName && boatInfo.boatName !== "52' V2ITESSE" && (
            <CustomButton href={boatInfo.boatUrl}>Explore now</CustomButton>
          )}
        </S.ButtonsWrapper>
        {matches && (
          <S.CustomNextArrow onClick={next}>
            <NavigateNextIcon />
          </S.CustomNextArrow>
        )}
      </Container>
    </S.Wrapper>
  )
}

export default ExploreBoats
